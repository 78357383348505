import { Link } from "react-router-dom";
import game1BackImg from "../images/game1-back.webp";
import game2BackImg from "../images/game2-back.webp";
import game3BackImg from "../images/game3-back.webp";

const blogsData = [
  {
    title: (
      <>
        Ζωντανό Παιχνίδι
        <br /> Καζίνο
      </>
    ),
    image: game1BackImg,
    description:
      "Ζήστε την ατμόσφαιρα ενός πραγματικού καζίνο με ζωντανή ροή! Παίξτε σε πραγματικό χρόνο με πραγματικούς ντίλερ, συμμετέχετε σε διαδραστικά παιχνίδια και απολαύστε μια αυθεντική εμπειρία καζίνο οπουδήποτε.",
  },
  {
    title: (
      <>
        Νέα για
        <br /> Παιχνίδια Καζίνο
      </>
    ),
    image: game2BackImg,
    description:
      "Μείνετε ενημερωμένοι για τα τελευταία παιχνίδια καζίνο! Διαβάστε νέα για κυκλοφορίες παιχνιδιών, στρατηγικές, τάσεις και αποκλειστικές ενημερώσεις για να παραμένετε μπροστά και να βρίσκετε τις καλύτερες ευκαιρίες.",
  },
  {
    title: (
      <>
        Τουρνουά
        <br /> Παιχνιδιών Καζίνο
      </>
    ),
    image: game3BackImg,
    description:
      "Συμμετάσχετε σε συναρπαστικά τουρνουά παιχνιδιών καζίνο και ανταγωνιστείτε παίκτες από όλο τον κόσμο! Δοκιμάστε τις δεξιότητές σας στο πόκερ, μπλακτζακ, κουλοχέρηδες και άλλα παιχνίδια σε καθημερινές και εποχιακές εκδηλώσεις.",
  },
];

const Blogs = () => {
  return (
    <>
      <section className="blog_area">
        <div className="container">
          <div className="blog_title">
            <h2>
              ΚΑΛΩΣ ΗΡΘΑΤΕ
              <br />
              ΣΤΟΝ ΚΟΣΜΟ ΤΩΝ ΠΑΙΧΝΙΔΙΩΝ ΚΑΖΙΝΟ.
            </h2>
          </div>
          <div className="blog_cnt_wrapper">
            {blogsData.map(({ title, image, description }, index) => (
              <div key={index} className="blog_cnt">
                <div className="blog_img">
                  <img src={image} alt={title} />
                </div>
                <div className="blog_cnt_inner">
                  <h3>
                    <Link to={"/"}>{title}</Link>
                  </h3>
                  <p>{description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
