import { Link } from 'react-router-dom'
import redesignImg from '../images/redesign.webp'

function Footer() {
  return (
    <footer className="footer-area">
    <div className="container">
      <div className="f-inner">
        <div className="f-left">
          <Link to="/">
            <img src={redesignImg} alt="ανασχεδιασμός" />
          </Link>
          <p>
            Αποποίηση ευθύνης: 
            Ετοιμαστείτε να βυθιστείτε στον κόσμο των συναρπαστικών παιχνιδιών που έχουν σχεδιαστεί αποκλειστικά για τη διασκέδασή σας. Το GoldenSpinGR προσφέρει μια δωρεάν διαδικτυακή κοινωνική πλατφόρμα καζίνο, όπου όλες οι λειτουργίες παιχνιδιών και οι ανταμοιβές είναι εικονικές και δεν έχουν πραγματική χρηματική αξία. Αυτό σημαίνει ότι μπορείτε να απολαύσετε τη συγκίνηση των παιχνιδιών καζίνο χωρίς τον κίνδυνο να χάσετε πραγματικά χρήματα. Σημειώστε ότι το GoldenSpinGR προορίζεται για άτομα ηλικίας άνω των 19 ετών. Με τη χρήση της πλατφόρμας μας, επιβεβαιώνετε ότι πληροίτε αυτή την απαίτηση ηλικίας.
          </p>
        </div>
        <div className="f-r">
          <div className="f-r-title">
            <h3>Πληροφορίες Επικοινωνίας</h3>
          </div>
          <div className="f-r-btm">
            <p>
              <span>Τοποθεσία:</span>
            </p>
            <p>
            Maria Papadopoulou Odos Athinas 30 105 54 Athens GREECE

            </p>
          </div>
          <div className="f-r-btm">
            <p>
              <span>Τηλέφωνο:</span>
            </p>
            <Link to="/">+30 21 5785 2290
            </Link>
          </div>
          <ul>
            <li>
              <Link to="/">Όροι Υπηρεσίας</Link>
            </li>
            <li>
              <Link to="/">Πολιτική Απορρήτου</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="copy">
        <span>
          © 2021 GoldenSpinGR.com Δημιουργήθηκε από{" "}
          <Link to="/">GoldenSpinGR.com</Link>
        </span>
      </div>
    </div>
  </footer>
  
  )
}

export default Footer
