import shape2Img from "../images/shape2.png";

const faqItems = [
  {
    question: "01. Πώς μπορώ να ξεκινήσω να παίζω παιχνίδια καζίνο;",
    answer:
      "Εγγραφείτε, κάντε κατάθεση και επιλέξτε το αγαπημένο σας παιχνίδι για να ξεκινήσετε να παίζετε.",
  },
  {
    question: "02. Μπορώ να παίξω δωρεάν παιχνίδια καζίνο;",
    answer: "Ναι, πολλά καζίνο προσφέρουν δωρεάν εκδόσεις επίδειξης των παιχνιδιών.",
  },
  {
    question: "03. Είναι ασφαλές να παίζω παιχνίδια καζίνο;",
    answer:
      "Ναι, όταν παίζετε σε αδειοδοτημένες και ρυθμιζόμενες πλατφόρμες καζίνο, οι πληροφορίες και τα κεφάλαιά σας είναι ασφαλή.",
  },
  {
    question: "04. Πώς μπορώ να διεκδικήσω μπόνους ή δωρεάν περιστροφές;",
    answer:
      "Διεκδικήστε μπόνους συνδεόμενοι στον λογαριασμό σας και χρησιμοποιώντας κωδικούς προώθησης ή ακολουθώντας τους όρους καμπάνιας.",
  },
  {
    question: "05. Ποια είναι η διαφορά μεταξύ παιχνιδιών τραπεζιού και παιχνιδιών κουλοχέρη;",
    answer:
      "Τα ζωντανά παιχνίδια καζίνο παίζονται σε πραγματικό χρόνο με πραγματικούς ντίλερ, ενώ τα συνηθισμένα παιχνίδια χρησιμοποιούν υπολογιστικά γραφικά.",
  },
  {
    question: "06. Μπορώ να συμμετάσχω σε τουρνουά παιχνιδιών καζίνο;",
    answer:
      "Ναι, πολλά καζίνο προσφέρουν τουρνουά όπου μπορείτε να ανταγωνιστείτε άλλους παίκτες για μεγάλα έπαθλα.",
  },
];
const Faqs = () => {
  return (
    <>
      <section className="faq_area">
        <div className="container">
          <div className="section-title">
            <h2>
              Συχνές
              <br /> Ερωτήσεις
            </h2>
            <p>
              Το καζίνο είναι ένας χώρος όπου διεξάγονται ορισμένα τυχερά παιχνίδια.
              Τα καζίνο συχνά συνδέονται με ξενοδοχεία και θέρετρα.
            </p>
          </div>
          <div className="faq_cnt_wrapper">
            <div className="faq-section">
              {faqItems.slice(0, 3).map(({ question, answer }, index) => (
                <div key={index} className="faq-item">
                  <input type="checkbox" id={`question-${index}-a`} />
                  <label htmlFor={`question-${index}-a`}>{question}</label>
                  <div className="answer">
                    <p>{answer}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="faq-section">
              {faqItems.slice(3).map(({ question, answer }, index) => (
                <div key={index} className="faq-item">
                  <input type="checkbox" id={`question-${index}-b`} />
                  <label htmlFor={`question-${index}-b`}>{question}</label>
                  <div className="answer">
                    <p>{answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="shape-3">
          <img src={shape2Img} alt="σχήμα" />
        </div>
      </section>
    </>
  );
};

export default Faqs;
