import logoMobileImg  from "../images/logo_mobile.png";
import { Link } from "react-router-dom";

const MobileMenu = ({menuOpen,setMenuOPen}) => {
  return (
    <>
     <div
  className="sidebar-menu"
  style={{ left: menuOpen ? '0px' : '-250px' }}
>
  <div className="menu-header">
    <div className="mobil-brand">
      <img
        className="img-fluid"
        src={logoMobileImg}
        alt="GoldenSpinGR"
      />
    </div>
    <div className="close-btn" onClick={() => setMenuOPen(false)}>
      <span className="close-icon"></span>
    </div>
  </div>
  <div className="menu-wrap">
    <div className="nav-c">
      <ul>
        <li>
          <Link to="/">αρχική</Link>
        </li>
        <li>
          <Link to="/">επικοινωνία</Link>
        </li>
      </ul>
    </div>
    <div className="h-btn">
      <Link to="/">
        εγγραφή <i className="fa-solid fa-arrow-right"></i>
      </Link>
    </div>
  </div>
</div>

    </>
  );
};

export default MobileMenu;
