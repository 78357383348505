import logoImg  from "../images/logo_mobile.png";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import { useState } from "react";

const Header = () => {
  const [menuOpen,setMenuOPen]=useState(false)
  return (
    <>
     <header className="header-area">
  <div className="container">
    <div className="h-inner">
      <div className="logo">
        <Link to="/">
          <img src={logoImg} alt="GoldenSpinGR" />
        </Link>
      </div>
      <div className="nav-c">
        <ul>
          <li>
            <Link to="/">αρχική</Link>
          </li>
          <li>
            <Link to="/">επικοινωνία</Link>
          </li>
        </ul>
      </div>
      <div className="menu-btn" onClick={() => setMenuOPen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</header>

  <MobileMenu menuOpen={menuOpen} setMenuOPen={setMenuOPen}/>
    </>
  );
};

export default Header;
