import { Link } from 'react-router-dom'
import  thumbImg from "../images/thumb.png";
import  cardImg from "../images/card.png";
import  aboutUsImg from "../images/about_us.png";
import  game1Img from "../images/game1.png";
import  game2Img from "../images/game2.png";
import  game3Img from "../images/game3.png";
import  shape2Img from "../images/shape2.png";
import EventCard from "./EventCard";
import Faqs from './Faqs';
import Blogs from './Blogs';
function Main() {
  return (
    <>
 <section className="banner-area">
  <div className="container">
    <div className="b_cnt_wrapper">
      <div className="b_left_cnt">
        <div className="shape-1">
          <img src={cardImg} alt="κάρτα" />
        </div>
        <h1>
          ΤΟ ΚΑΛΥΤΕΡΟ ΔΩΡΕΑΝ ΚΟΙΝΩΝΙΚΟ <span>ΠΑΙΧΝΙΔΙ ΚΑΖΙΝΟ</span> Παίξε σήμερα.
        </h1>
        <p>
          GoldenSpinGR.com - το δωρεάν και διασκεδαστικό κοινωνικό σας καζίνο
        </p>
        <div className="b_btn_area">
          <div className="h-btn">
            <Link to="/">
              <i className="fa-regular fa-circle-play"></i>
              Παίξε τώρα
            </Link>
          </div>
          <div className="h-btn">
            <Link to="/">εγγραφή</Link>
          </div>
        </div>
      </div>
      <div className="b_right_cnt">
        <img src={thumbImg} alt="αντίχειρας" />
      </div>
    </div>
  </div>
</section>
<section className="about-us-area">
  <div className="container">
    <div className="about_cnt_wrapper">
      <div className="shape-2">
        <img src={cardImg} alt="κάρτα" />
      </div>
      <div className="about_right_cnt">
        <div className="about_left_img">
          <img src={aboutUsImg} alt="σχετικά με εμάς" />
        </div>
        <h2>Σχετικά με το Καζίνο</h2>
        <p>
          Το καζίνο είναι ένας χώρος όπου διεξάγονται ορισμένα τυχερά παιχνίδια.
          Τα καζίνο βρίσκονται συχνά κοντά σε ξενοδοχεία, θέρετρα, εστιατόρια,
          καταστήματα λιανικής, κρουαζιερόπλοια και άλλες τουριστικές
          ατραξιόν. Ορισμένα καζίνο είναι επίσης γνωστά για τη φιλοξενία ζωντανών
          παραστάσεων όπως stand-up comedy, συναυλίες και αθλητικά γεγονότα.
        </p>
        <div className="h-btn">
          <Link to="/">Επικοινωνήστε μαζί μας</Link>
        </div>
      </div>
      <div className="about_left_cnt">
        <img src={aboutUsImg} alt="σχετικά με εμάς" />
      </div>
    </div>
  </div>
</section>
<section className="top_upcoming-_event">
  <div className="container">
    <div className="section-title">
      <h2>Κορυφαία Μεγάλα Παιχνίδια</h2>
      <p>
        Ζήστε τη συγκίνηση του ζωντανού καζίνο σε πραγματικό χρόνο με τη δυνατότητα
        ζωντανής ροής! Παίξτε με πραγματικούς dealers, αλληλεπιδράστε με άλλους
        παίκτες και απολαύστε μια συναρπαστική εμπειρία καζίνο οποτεδήποτε,
        οπουδήποτε.
      </p>
    </div>
    <div className="event_wrapper">
      <EventCard
        to={"/bigmax"}
        image={game1Img}
        title={"Bigmax Διαμάντια και Wilds Παιχνίδι Καζίνο"}
        description={
          "Ζήστε τη συγκίνηση του Bigmax Διαμάντια και Wilds! Γυρίστε τους τροχούς για να κυνηγήσετε λαμπερά διαμάντια και σύμβολα wild, και κερδίστε μεγάλες ανταμοιβές με γύρους μπόνους, δωρεάν περιστροφές και τζακπότ."
        }
      />
      <EventCard
        to={"/king"}
        image={game2Img}
        title={"Βασιλιάς της Αιγύπτου Παιχνίδι Καζίνο"}
        description={
          "Εξερευνήστε τον αρχαίο κόσμο της Αιγύπτου με το παιχνίδι καζίνο Βασιλιάς της Αιγύπτου! Ανακαλύψτε χαμένους θησαυρούς, γυρίστε για μεγάλες νίκες και ζήστε το μυστήριο των Φαραώ με εντυπωσιακά γραφικά, wilds και γύρους μπόνους."
        }
      />
      <EventCard
        to={"/fish"}
        image={game3Img}
        title={"Μεγάλο Ψάρι Παιχνίδι Καζίνο"}
        description={
          "Βυθιστείτε στα βάθη με το παιχνίδι καζίνο Μεγάλο Ψάρι! Απολαύστε μια ποικιλία συναρπαστικών παιχνιδιών όπως φρουτάκια, πόκερ και blackjack. Παίξτε με φίλους, κερδίστε μεγάλες ανταμοιβές και συλλέξτε μπόνους!"
        }
      />
    </div>
  </div>
</section>
<section className="why_play_area">
  <div className="container">
    <div className="why_cnt_wrapper">
      <div className="why_cnt_left">
        <h2>Γιατί να Παίξετε στο Καζίνο μας</h2>
        <p>
          Το καζίνο είναι ένας χώρος όπου διεξάγονται ορισμένα τυχερά παιχνίδια.
          Τα καζίνο συνδέονται συχνά με ξενοδοχεία και θέρετρα.
        </p>
        <p>
          <span>
            Debitis ad dolor sint consequatur hic, facere est doloribus
            tempora in laborum similique saepe blanditiis odio nulla
            repellat dicta reprehenderit. Obcaecati, sed perferendis? Quam
            cum debitis odit recusandae dolor earum.
          </span>
        </p>
      </div>
      <div className="why_cnt_right">
        <div className="why_cnt_right_inner">
          <i className="fa-solid fa-shield-halved"></i>
          <h3>Ασφαλή Παιχνίδια Καζίνο</h3>
          <p>
            Τα παιχνίδια καζίνο είναι συνηθισμένες μορφές τυχερών παιχνιδιών.
            Υπάρχουν πολλές επιλογές σε παιχνίδια καζίνο.
          </p>
        </div>
        <div className="why_cnt_right_inner">
          <i className="fa-solid fa-dice-six"></i>
          <h3>Υπέροχη Εμπειρία Παιχνιδιού</h3>
          <p>
            Τα παιχνίδια καζίνο είναι συνηθισμένες μορφές τυχερών παιχνιδιών.
            Υπάρχουν πολλές επιλογές σε παιχνίδια καζίνο.
          </p>
        </div>
        <div className="why_cnt_right_inner">
          <i className="fa-solid fa-trophy"></i>
          <h3>Υψηλότερες Ευκαιρίες Νίκης</h3>
          <p>
            Τα παιχνίδια καζίνο είναι συνηθισμένες μορφές τυχερών παιχνιδιών.
            Υπάρχουν πολλές επιλογές σε παιχνίδια καζίνο.
          </p>
        </div>
        <div className="why_cnt_right_inner">
          <i className="fa-solid fa-coins"></i>
          <h3>Επένδυση, Νίκη και Κέρδος</h3>
          <p>
            Τα παιχνίδια καζίνο είναι συνηθισμένες μορφές τυχερών παιχνιδιών.
            Υπάρχουν πολλές επιλογές σε παιχνίδια καζίνο.
          </p>
        </div>
      </div>
    </div>
    <div className="shape-3">
      <img src={shape2Img} alt="σχήμα" />
    </div>
  </div>
</section>

      <Faqs/>
      <Blogs/>
   </>
  )
}

export default Main
